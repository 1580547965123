import React from "react";
import "./header.css";

export const Header = ({
  text,
  h5,
  h4,
  h2,
  h3,
  span,
  errorStyle,
  children,
  ...props
}) => {
  if (h4) {
    return <h4 className='h4'>{text}</h4>;
  }
  if (h5) {
    return <h5 className='h5'>{text}</h5>;
  }
  if (h2) {
    return <h2 className={errorStyle ? "h2 error-style" : "h2"}>{text}</h2>;
  }
  if (h3) {
    return <h2 className={errorStyle ? "h3 error-style" : "h2"}>{text}</h2>;
  }
  if (span) {
    return (
      <h2 className={errorStyle ? "span span-error error-style" : "span"}>
        {text}
      </h2>
    );
  }
};
