import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/carte de visite-mathilde renaudin architecte_recto-1.png";
import "./navbar.css";

export const Navbar = () => {
  const sendMail = () => {
    window.location = "mailto: atelier@mathilderenaudin.com?";
  };
  return (
    <nav>
      <NavLink to={"/"}>
        <div className='logo-wrapper'>
          {/* <h1 className='nav-header'>
          Mathilde <br /> Renaudin <br /> architecte
        </h1> */}
          <img src={logo} alt='mathilde-renaudin-architecte' />
        </div>
      </NavLink>
      <div className='link-container'>
        <ul>
          <li>
            <NavLink style={{ color: "black", textDecoration: "none" }} to='/'>
              Accueil
            </NavLink>
          </li>
          <li>
            {/* <NavLink
              style={{ color: "black", textDecoration: "none" }}
              to='/portfolio'>
              Portfolio
            </NavLink>
          </li>
          <li>
            <NavLink
              style={{ color: "black", textDecoration: "none" }}
              to='/about'>
              About
            </NavLink> */}
          </li>
          <li onClick={sendMail}>Contacter</li>
        </ul>
        <div className='social-link-container'>
          {/* <a href='facebook.com' target='blank'>
            <img
              className='social-image'
              src='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/facebook/facebook-plain.svg'
              alt='facebook'
            />
          </a> */}
          <a href='https://www.instagram.com/mathilderenaudin_/' target='blank'>
            <img
              className='social-image'
              src='https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/132px-Instagram_logo_2016.svg.png?20210403190622'
              alt='insta'
            />
          </a>
          {/* <a href='twitter.com' target='blank'>
            <img
              className='social-image'
              src='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/twitter/twitter-original.svg'
              alt='twitter'
            />
          </a>
          <a href='linkedin.com' target='blank'>
            <img
              className='social-image'
              src='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linkedin/linkedin-original.svg'
              alt='linkedin'
            />
          </a> */}
        </div>
      </div>
    </nav>
  );
};
