import React from "react";
import primaryPhoto from "../../assets/images/HF_01042019_MILIEU_MATHILDE_124.jpg";
import "./largeImage.css";

export const LargeImage = () => {
  return (
    <div className='large-image-container'>
      <img
        className='large-image'
        src={primaryPhoto}
        alt='taken-By-@HubertFanthomme'
      />
      <span
        style={{ fontSize: ".75em", fontFamily: "poppins", color: "#c7c7c7" }}>
        Photographe: @HubertFanthomme
      </span>
    </div>
  );
};
