import React from "react";
import "./container.css";

export const Container = ({ children, column, delay, ...props }) => {
  return (
    <div
      style={{
        flexDirection: column ? "column" : "row",
      }}
      className={delay ? "delay container-component " : "container-component"}>
      {children}
    </div>
  );
};
