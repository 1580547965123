import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Navbar } from "./containers/Navbar/Navbar";
import { Home, Portfolio, ErrorPage } from "./pages/index";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        {/* <Route path='/portfolio' element={<Portfolio />} />
        <Route path="/about" element{<About />} /> */}
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
