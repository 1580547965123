import React, { Suspense } from "react";
import { Container } from "../components/Container/Container";
import { LargeImage } from "../components/LargeImage/LargeImage";
import { Header } from "../components/Header/Header";
import { Button } from "../components/Button/Button";

const Home = () => {
  return (
    <main>
      <Container column={true}>
        <Header text={"Dijon - Paris"} h5={true} />
        <Suspense fallback={<></>}>
          <LargeImage />
        </Suspense>
      </Container>

      {/* <Container delay={true} column={true}>
        <Header text={"mon portfolio"} h4={true} />
        <Button text={"telecharger"} />
      </Container> */}
    </main>
  );
};

export default Home;
