import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from "../components/Container/Container.jsx";
import { Header } from "../components/Header/Header.jsx";

const ErrorPage = () => {
  return (
    <>
      <Container column={true}>
        <Header span={true} errorStyle={true} text={"404 Error"} />
        <Header h2={true} errorStyle={true} text={"Opps..."} />
        <Header
          h3={true}
          errorStyle={true}
          text={"Looks like something went wrong"}
        />
        <NavLink
          style={{
            margin: "1.5em 0",
            textDecoration: "none ",
            color: "#fff",
            fontWeight: "600",
            fontFamily: "Inter",
            fontSize: "1em",
            backgroundColor: "#000",
            width: "200px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50px",
            cursor: "pointer",
          }}
          to='/'>
          Go Back Home
        </NavLink>
      </Container>
    </>
  );
};
export default ErrorPage;
